<template>
  <div>
    <Pane> </Pane>

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="编码">
              <a-input
                v-decorator="[
                  'code',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="会议室名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="地点">
              <a-cascader
                placeholder=""
                :options="locationList"
                :fieldNames="{
                  label: 'name',
                  value: 'value',
                  children: 'children',
                }"
                v-decorator="[
                  'location',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="容纳人数">
              <a-input
                v-decorator="[
                  'accommodation',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="自动审核">
              <a-radio-group
                v-decorator="[
                  'autoReview',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in booleanList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'state',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="设备列表"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-checkbox-group
                v-decorator="[
                  'facilityList',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                :options="facilityTypeList.map((item) => item.name)"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remark']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/meeting";
import { mapGetters } from "vuex";

export default {
  name: "addMeetingRoom",

  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    locationList() {
      return this.findDataDict("meeting-room-location");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
    statusList() {
      return this.findDataDict("meetingRoomState");
    },
    facilityTypeList() {
      return this.findDataDict("facility-type");
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let location, floor;
          if (Array.isArray(values.location)) {
            location = values.location[0];
            floor = values.location[1];
          }

          let facilityList = [];
          if (Array.isArray(values.facilityList)) {
            facilityList = values.facilityList.map((name) => {
              const obj = this.facilityTypeList.find(
                (item) => item.name === name
              );
              return {
                code: obj ? obj.value : "",
                name,
              };
            });
          }

          this.saveLoading = true;
          add({
            ...values,
            location,
            floor,
            facilityList,
          })
            .then(() => {
              this.form.resetFields();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
